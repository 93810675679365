import React, { useState, useEffect } from "react";

export default function SessionTimer(props) {
  const { sessionTime, sessionCallback } = props;
  const [sessionStarted, setSessionStarted] = useState(false);

  const startSessionTimer = (callback, minutes) => {
    // Start a timer
    let sessionTimer = window.setInterval(callback, minutes * 60 * 1000);
    // Function used to refresh the timer
    const refreshSession = function () {
      clearInterval(sessionTimer);
      sessionTimer = window.setInterval(callback, minutes * 60 * 1000);
    };
    // If user clicks something or presses a key reset the timer
    document.addEventListener("click", refreshSession);
    document.addEventListener("keydown", refreshSession);
    document.addEventListener("mousemove", refreshSession);
    document.addEventListener("scroll", refreshSession);
  };

  useEffect(() => {
    if (!sessionStarted) {
      startSessionTimer(() => {
        sessionCallback();
      }, sessionTime);
      setSessionStarted(true);
    }
  }, [sessionCallback, sessionStarted, sessionTime]);

  return <div></div>;
}
