import React, { useEffect } from "react";
import { Grid } from "@mui/material";
// Custom imports
import { userIsAuthenticated } from "../../helpers/common/userIsAuthenticated";

export default function LoginRedirect(props) {
  const { authPath, loadingComponent: Loading } = props;

  useEffect(() => {
    if (userIsAuthenticated()) {
      window.location.assign(authPath);
    } else {
      window.location.assign(
        `${window.CONFIG.AUTH_UI_URL}/?referrer=${window.CONFIG.APP_NAME}`
      );
    }
  }, [authPath]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Loading />
      </Grid>
    </Grid>
  );
}
