import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
// Material-UI imports
import {
  Typography,
  Button,
  AppBar,
  Hidden,
  Drawer,
  Box,
  IconButton
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// Custom imports
import { logOut } from "../../helpers/common/logOut";
import BrandMark from "../../assets/brandMark.svg";
import UserIcon from "../../assets/iconUser.svg";

export default function Navigation(props) {
  const { brandMarkLink, links, firstName, lastName } = props;

  const [open, setOpen] = useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <Box
      sx={{
        flex: "0 0 100%",
        height: "80px"
      }}
    >
      <AppBar
        id="appHeader"
        square
        elevation={1}
        position="fixed"
        sx={{
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "80px",
          zIndex: 9999,
          padding: 0,
          color: "#444"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content"
          }}
        >
          <NavLink
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none"
            }}
            to={brandMarkLink}
          >
            <Box
              id="logoContainer"
              sx={{
                display: "flex",
                height: "80px",
                alignItems: "center",
                bgcolor: "primary.main",
                "& img": {
                  width: "32px",
                  padding: "16px 24px",
                  "& .st0": {
                    fill: "#fff !important",
                    stroke: "#fff !important"
                  }
                }
              }}
            >
              <img src={BrandMark} alt="Global Holdings" width="80" />
            </Box>
          </NavLink>
          <Hidden smDown>
            <div
              style={{
                display: "flex"
              }}
            >
              {links.length > 0 &&
                links
                  .sort((a, b) => {
                    return a.order > b.order ? 1 : -1;
                  })
                  .map(link => {
                    return (
                      <Fragment key={`desktop-${link.title}`}>
                        <NavLink className="navigationLink" to={link.href}>
                          {link.title}
                        </NavLink>
                      </Fragment>
                    );
                  })}
            </div>
          </Hidden>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "8px"
          }}
        >
          <Hidden smDown>
            <img
              src={UserIcon}
              width="32"
              style={{
                marginRight: "8px"
              }}
              id="userIcon"
              alt="User Icon"
            />
            <Typography
              component="p"
              variant="body1"
              sx={{
                display: "none",
                "@media (min-width: 720px)": {
                  display: "flex"
                }
              }}
            >
              {sessionStorage.getItem("firstName") || firstName}{" "}
              {sessionStorage.getItem("lastName") || lastName}
            </Typography>
            {sessionStorage.getItem("referrer") !== "companyWebsite" && (
              <IconButton
                onClick={logOut}
                variant="outlined"
                color="primary"
                sx={{
                  cursor: "pointer",
                  borderRadius: 0,
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#333",
                  fontSize: "1rem",
                  fontWeight: "normal",
                  outline: "none",
                  borderBottom: `4px solid transparent`
                }}
              >
                {" "}
                <ExitToAppIcon color="primary" />{" "}
              </IconButton>
            )}
          </Hidden>
          <Hidden mdUp>
            <Button
              color="primary"
              aria-label="open drawer"
              aria-controls="navigation-menu"
              onClick={handleDrawer}
              edge="start"
              className={clsx(/*classes.menuButton, open && classes.hide*/)}
            >
              <MenuOutlinedIcon />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              sx={{
                width: "200px"
              }}
              anchor="left"
              open={open}
              onClose={handleDrawerClose}
            >
              <Box
                sx={{
                  borderBottom: "1px solid #eee"
                }}
              >
                <Button
                  aria-controls="navigation-menu"
                  aria-label="close navigation menu"
                  className={"a" /*classes.menuButton*/}
                  color="primary"
                  onClick={handleDrawerClose}
                >
                  <ChevronLeftOutlinedIcon />
                </Button>
              </Box>
              <div>
                {links.length > 0 &&
                  links
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map(link => {
                      return (
                        <div key={`mobile-${link.title}`}>
                          <NavLink
                            className={"a"}
                            to={link.href}
                            onClick={handleDrawerClose}
                          >
                            {link.title}
                          </NavLink>
                        </div>
                      );
                    })}
                {sessionStorage.getItem("referrer") !== "companyWebsite" && (
                  <IconButton
                    onClick={logOut}
                    variant="outlined"
                    color="primary"
                    sx={{
                      cursor: "pointer",
                      borderRadius: 0,
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "#333",
                      fontSize: "1rem",
                      fontWeight: "normal",
                      outline: "none",
                      borderBottom: `4px solid transparent`
                    }}
                  >
                    {" "}
                    <ExitToAppIcon color="primary" />{" "}
                  </IconButton>
                )}
              </div>
            </Drawer>
          </Hidden>
        </Box>
      </AppBar>
    </Box>
  );
}
