import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import App from "./containers/app";
import "./styles.css";
import { Global, css } from "@emotion/react";

require("typeface-poppins");

const theme = createTheme({
  palette: {
    primary: {
      main: "#1373e6",
      light: "#fff"
    },
    secondary: {
      main: "#fff",
      contrastText: "#1373e6"
    }
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
          height: 60
        },
        colorDefault: {
          backgroundColor: "#ffffff"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "16px 40px",
          borderBottom: "1px solid #eee"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          flexShrink: 1,
          textTransform: "none",
          "@media (min-width: 600px)": {
            minWidth: "80px"
          }
        }
      }
    },
    PrivateTabIndicator: {
      styleOverrides: {
        root: {
          bottom: "10px",
          height: "3px"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "1.15rem",
          fontWeight: "600",
          color: "#888"
        },
        h2: {
          fontSize: "1rem",
          fontWeight: "600",
          letterSpacing: "-.03rem"
        },
        body2: {
          fontSize: "0.75rem"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "22px"
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: "1px solid #ddd"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none"
        },
        sizeSmall: {
          padding: "4px 8px"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #ddd"
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #ddd"
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          display: "none"
        }
      }
    }
  }
});
render(
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        body * {
          font-family: "Poppins", sans-serif !important;
        }
        .MuiButton-root {
          border-radius: 24px !important;
          text-transform: none !important;
        }
        .MuiButton-contained {
          boxshadow: none !important;
        }
        .MuiButton-contained:hover {
          boxshadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2),
            0px 1px 2px 0px rgba(0, 0, 0, 0.14),
            0px 0px 3px 0px rgba(0, 0, 0, 0.12) !important;
        }
      `}
    />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
