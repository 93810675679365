export function userIsAuthenticated() {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken =
    urlParams.get("accesstoken") || urlParams.get("accessToken");
  const username = urlParams.get("username") || urlParams.get("userName");
  const refreshToken =
    urlParams.get("refreshtoken") || urlParams.get("refreshToken");
  const referrer = urlParams.get("referrer");
  if (accessToken && refreshToken && username) {
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("referrer", referrer);
    return true;
  }
  if (
    sessionStorage.getItem("accessToken") &&
    sessionStorage.getItem("userName") &&
    sessionStorage.getItem("refreshToken")
  ) {
    return true;
  }
  return false;
}
