import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Custom imports
import Layout from "./layout";
import Loading from "../components/common/loading";
import LoginRedirect from "../components/common/loginRedirect";
import PrivateRoute from "../components/common/privateRoute";

import { createResponseInterceptor } from "../helpers/common/createResponseInterceptor";

const AttritionScore = lazy(() => import("./attritionScore"));

const App = () => {
  createResponseInterceptor(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_CLIENT_SECRET
  );
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/attrition-score"
              component={() => <AttritionScore />}
            />
            <Route
              exact
              path="/"
              component={() => (
                <LoginRedirect
                  authPath={"/attrition-score"}
                  loadingComponent={() => <Loading />}
                />
              )}
            />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
};

export default App;
