import axios from "axios";

export function createResponseInterceptor(appName, appSecret) {
  const interceptor = axios.interceptors.response.use(
    // If the request succeeds, return the original response
    response => {
      return response;
    },
    // If there is an error, use the Interceptor to handle the error.
    error => {
      const status = error.response ? error.response.status : null;
      // Reject the Promise if the error is not a 401 error.
      if (status !== 401) {
        return Promise.reject(error);
        // When the response code is a 401, try to refresh the Bearer token.
      } else {
        // Eject the interceptor so it doesn't loop in case token refresh causes the 401 response
        axios.interceptors.response.eject(interceptor);
        // Post the refresh token to the API in order to receive a new Bearer token.
        const api = `${window.CONFIG.AUTH_API_URL}/auth/token`;
        return axios({
          method: "POST",
          url: api,
          withCredentials: true,
          crossdomain: true,
          data: {
            refreshToken: sessionStorage.getItem("refreshToken"),
            expiredAccessToken: sessionStorage.getItem("accessToken"),
            AppName: appName,
            AppSecret: appSecret
          }
        })
          .then(response => {
            // Save the new tokens and resolve the original Promise as true
            const { accessToken, expires, refreshToken } = response.data;
            sessionStorage.setItem("accessToken", accessToken);
            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("expires", expires);
            return Promise.resolve(true);
          })
          .catch(error => {
            return Promise.reject(error);
          });
      }
    }
  );
}
