export function logOut() {
  const hostname = window.location.hostname;

  if (sessionStorage.getItem("referrer") === "companyWebsite") {
    // Use the Dev in dev & local environments
    if (
      hostname.indexOf("dev") !== -1 ||
      hostname.indexOf("localhost") !== -1
    ) {
      window.location.assign("https://dev.company.ghllc.com/HomeTab.aspx");
    }
    // QA API in QA environment
    else if (hostname.indexOf("qa") !== -1) {
      window.location.assign("https://qa.company.ghllc.com/HomeTab.aspx");
    }
    //Test environment
    else if (hostname.indexOf("test") !== -1) {
      window.location.assign("https://test.company.ghllc.com/HomeTab.aspx");
    }
    // Prod
    else {
      window.location.assign("https://company.ghllc.com/HomeTab.aspx");
    }
  } else {
    sessionStorage.clear();
    window.location.assign("/");
  }
}
