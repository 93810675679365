import React from "react";
// Material-UI imports
import { Box, CircularProgress } from "@mui/material";

export default function Loading() {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        minHeight: "300px",
        zIndex: 100
      }}
    >
      <Box component="div" visibility="hidden">
        Loading...
      </Box>
      <CircularProgress
        size={24}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px"
        }}
      />
    </Box>
  );
}
