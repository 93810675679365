import React, { useState, useEffect } from "react";
// Material-UI imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button
} from "@mui/material";
//import { useTheme } from "@mui/styles";

export default function SessionModal(props) {
  //const classes = useStyles();
  //const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [counter, setCounter] = useState(props.counter);
  const [counterStarted, setCounterStarted] = useState(false);

  useEffect(() => {
    if (counterStarted && counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, counterStarted]);

  useEffect(() => {
    if(props.open) setCounterStarted(true);
    if (counter < 1) {
      props.timeOutFunction();
    }
  }, [counter, props]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.open}
      onEnter={() => {
        setCounter(props.counter);
        setCounterStarted(true);
      }}
    >
      <DialogTitle disableTypography tabIndex="0">
        <Typography component="h3" variant="h6">
          Session Timeout
        </Typography>
      </DialogTitle>
      <DialogContent id="timeout-dialog-content" tabIndex="0">
        <Box p={2} className={"a" /*classes.dialogContent*/}>
          <Typography component="p">
            Your session will expire in {counter} seconds
          </Typography>
        </Box>
        <Button
          color="primary"
          className={"a" /*classes.modalActionButton*/}
          onClick={() => {
            setCounterStarted(false);
            props.handleModalAction();
          }}
        >
          Continue Working
        </Button>
      </DialogContent>
    </Dialog>
  );
}

/*const useStyles = makeStyles(theme => ({
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.main
    },
    "&:focus": {
      color: theme.palette.primary.main
    },
    margin: theme.spacing(-1.5, 0)
  },
  dialogContent: {
    display: "flex"
  },
  modalActionButton: {
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    float: "right"
  }
}));*/
