import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";

const http = axios.create({
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: true,
    cacheFlag: "useCache"
  })
});

export default function fetchApi(
  method,
  url,
  data = {},
  useCache = true,
  accessToken = sessionStorage.getItem("accessToken")
    ? `Bearer ${sessionStorage.getItem("accessToken")}`
    : null
) {
  return new Promise((resolve, reject) => {
    const config = {
      method: method,
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: accessToken
      },
      useCache: useCache
    };
    http(config)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
